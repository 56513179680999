import {
  CreateEventPage,
  DashboardPage,
  EventDetailsPage,
  EventMetricsPage,
  EventsPage,
  LandingPage,
  LoginPage,
  RegisterPage,
  UserManagementPage,
  PrivacyPolicy,
} from './components/pages'

const routeComponents = {
  dashboard: DashboardPage,
  events: EventsPage,
  'event-detail': EventDetailsPage,
  'event-metrics': EventMetricsPage,
  'new-event': CreateEventPage,
  login: LoginPage,
  'user-management': UserManagementPage,
  landing: LandingPage,
  register: RegisterPage,
  'privacy-policy': PrivacyPolicy,
}

export default routeComponents
