// HorizontalMenu.jsx
import React, { memo, useCallback } from 'react'
import { Tab, Tabs, useTheme, useMediaQuery } from '@mui/material'

const HorizontalMenu = memo(({ menu, onTabChange }) => {
  const [selectedTab, setSelectedTab] = React.useState(0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChange = useCallback(
    (event, newValue) => {
      setSelectedTab(newValue)
      if (onTabChange) {
        onTabChange(newValue)
      }
    },
    [onTabChange]
  )

  const tabStyle = {
    textTransform: 'none',
    minWidth: 0,
    width: 'auto',
    padding: isMobile ? '4px 8px' : '6px 12px',
    background: 'inherit',
    color: '#98A2B3 !important',
    fontWeight: 500,
    fontSize: isMobile ? '12px' : '14px',
  }

  const selectedTabStyle = {
    ...tabStyle,
    background: '#344054',
    borderRadius: '4px',
    color: 'white !important',
  }

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      aria-label="horizontal menu"
      variant="scrollable"
      scrollButtons={false}
      TabIndicatorProps={{ style: { display: 'none' } }}
      sx={{
        background: 'inherit !important',
        minHeight: isMobile ? '32px' : '48px',
        '.MuiTabs-flexContainer': {
          justifyContent: isMobile ? 'flex-end' : 'flex-start',
          gap: isMobile ? '4px' : '8px',
        },
        '.MuiTab-root': {
          minHeight: isMobile ? '32px' : '48px',
        }
      }}
    >
      {menu.map((item, index) => (
        <Tab
          key={index}
          sx={{ color: selectedTab === index ? 'white !important' : '#98A2B3 !important' }}
          label={item}
          style={selectedTab === index ? selectedTabStyle : tabStyle}
        />
      ))}
    </Tabs>
  )
})

export default HorizontalMenu
