import React, { forwardRef, useState } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'

const faqData = [
  {
    question: '¿Qué es MangoTicket y cómo puede ayudarme en la gestión de eventos?',
    answer:
      'MangoTicket es una plataforma integral que permite a los organizadores de eventos gestionar y vender entradas de manera eficiente. Con herramientas intuitivas, puedes crear eventos, personalizar tus páginas y ofrecer una experiencia única a tus asistentes. Además, puedes gestionar productos de barra y maximizar tus ingresos.',
  },
  {
    question: '¿Es seguro utilizar MangoTicket para gestionar ventas y datos personales?',
    answer:
      'Sí, la seguridad de nuestros usuarios es una prioridad. Implementamos protocolos de encriptación y protección de datos para garantizar transacciones seguras. Puedes confiar en que tu información y la de tus clientes están protegidas.',
  },
  {
    question: '¿Cuánto cuesta utilizar MangoTicket y qué opciones de precios ofrecen?',
    answer:
      'Los costos de MangoTicket varían según el tipo de evento y los servicios que elijas. Ofrecemos diferentes planes para adaptarnos a tus necesidades específicas. Contáctanos para recibir una propuesta personalizada.',
  },
  {
    question: '¿Qué puedo gestionar con MangoTicket?',
    answer:
      'Con MangoTicket puedes administrar de manera integral la venta de tickets y productos de barra para tu evento. Gestiona inventarios, precios y ventas desde un solo lugar facilitando el control y seguimiento de todas tus operaciones.',
  },
  {
    question: '¿Qué tipo de soporte ofrece MangoTicket en caso de necesitar ayuda?',
    answer:
      'Ofrecemos soporte técnico a través de chat en vivo y correo electrónico. Nuestro equipo está disponible para ayudarte en cualquier momento, asegurando que tu experiencia sea fluida. No dudes en contactarnos si necesitas asistencia.',
  },
]

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <Box
      sx={{
        borderBottom: '1px solid #aaa',
      }}
    >
      <Button
        onClick={onClick}
        aria-expanded={isOpen}
        sx={{
          backgroundColor: 'transparent',
          color: '#fff',
          border: 'none',
          fontSize: '18px',
          fontWeight: 600,
          padding: '20px 0',
          width: '100%',
          textAlign: 'left',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          textTransform: 'none',
        }}
      >
        {question}
        <Box
          component="img"
          src="https://mangoticket.sfo3.cdn.digitaloceanspaces.com/landing/faq.svg"
          alt={isOpen ? 'Collapse' : 'Expand'}
          sx={{
            width: '32px',
            height: '32px',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </Button>
      {isOpen && (
        <Box
          sx={{
            color: '#aaa',
            fontSize: '16px',
            lineHeight: 1.5,
            paddingBottom: '24px',
          }}
        >
          {answer}
        </Box>
      )}
    </Box>
  )
}

const FAQ = forwardRef((props, ref) => {
  const [openIndex, setOpenIndex] = useState(null)

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <Box
      ref={ref}
      component="section"
      sx={{
        backgroundColor: '#041e2b',
        padding: { xs: '100px 20px', md: '112px 64px' },
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            gap: '80px',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '40px', md: '48px' },
                fontFamily: '"Plus Jakarta Sans", sans-serif',
                fontWeight: 700,
                lineHeight: 1.2,
                marginBottom: '16px',
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              Preguntas
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                fontFamily: '"Inter", sans-serif',
                fontWeight: 400,
                color: '#aaa',
                marginBottom: '24px',
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              Aquí encontrarás respuestas a las dudas más frecuentes sobre MangoTicket y su funcionamiento.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-start' },
                marginBottom: { xs: '8px', md: '0' },
                marginTop: { xs: '4px', md: '0' },
              }}
            >
              <Button
                onClick={props.onContact}
                sx={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: '1px solid #f79009',
                  borderRadius: '10px',
                  padding: '12px 24px',
                  fontSize: '16px',
                  cursor: 'pointer',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(247, 144, 9, 0.1)',
                  },
                }}
              >
                Contáctanos
              </Button>
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            {faqData.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
                isOpen={openIndex === index}
                onClick={() => handleClick(index)}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  )
})

export default FAQ
