import React, { useCallback, useState, useEffect } from 'react'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EventIcon from '@mui/icons-material/Event'

import { useUsersData } from '../../../hooks/userManagement/useUsersData'
import { DashboardLayout, DashboardNavbar } from '../../templates'
import { AddEditUserDialog, SendCourtesiesDialog, UserTable } from '../../organisms'
import { CSVUploader } from '../../molecules'
import { LoadingSpinner } from '../../atoms'
import { useBatchCreateUsers } from '../../../hooks/userManagement/useBatchCreateUsers'
import { useNotification } from '../../../contexts/NotificationContext'
import { useDeleteUser } from '../../../hooks/userManagement/useDeleteUser'
import { useUpdateUser } from '../../../hooks/userManagement/useUpdateUser'

const UserManagementPage = () => {
  console.log('UserManagementPage render')
  const { usersData, loading, error, refetch } = useUsersData()
  const [open, setOpen] = useState(false)
  const [isSendCourtesiesDialogOpen, setIsSendCourtesiesDialogOpen] = useState(false)
  const [editingUser, setEditingUser] = useState(null)
  const [selectedUsers, setSelectedUsers] = useState([])
  const { createBatchUsers } = useBatchCreateUsers()
  const { showNotification } = useNotification()
  const { deleteUser, batchDeleteUsers } = useDeleteUser()
  const { updateUser } = useUpdateUser()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    console.log('UserManagementPage useEffect - Data received:', {
      usersCount: usersData?.length,
      loading,
      error: error?.message
    })
  }, [usersData, loading, error])

  const handleOpen = useCallback(() => {
    setEditingUser(null)
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    setEditingUser(null)
  }, [])

  const handleEditUser = useCallback((user) => {
    setEditingUser(user)
    setOpen(true)
  }, [])

  const handleOpenSendCourtesiesDialog = useCallback(() => {
    setIsSendCourtesiesDialogOpen(true)
  }, [])

  const handleCloseSendCourtesiesDialog = useCallback(() => {
    setIsSendCourtesiesDialogOpen(false)
  }, [])

  const handleSelectUser = useCallback((userId) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId)
      }
      return [...prevSelected, userId]
    })
  }, [])

  const handleSelectAllUsers = useCallback((isAllSelected) => {
    if (isAllSelected) {
      const allUserIds = usersData?.map((user) => user.id) || []
      setSelectedUsers(allUserIds)
    } else {
      setSelectedUsers([])
    }
  }, [usersData])

  const handleFileUpload = useCallback(async (fileContent) => {
    try {
      const firstLine = fileContent.split('\n')[0]
      const separator = firstLine.includes(';') ? ';' : ','
      
      const lines = fileContent.split('\n')
      const headers = lines[0].split(separator).map(header => header.trim())
      
      const users = lines.slice(1)
        .filter(line => line.trim())
        .map(line => {
          const values = line.split(separator).map(value => value.trim())
          const user = {}
          headers.forEach((header, index) => {
            if (header === 'name' || header === 'email') {
              user[header] = values[index]
            }
          })
          return user
        })
        .filter(user => user.email && user.name)

      if (users.length === 0) {
        throw new Error('No se encontraron usuarios válidos en el archivo CSV')
      }

      await createBatchUsers(users)
      await refetch()
      showNotification(`${users.length} usuarios creados exitosamente`, 'success')
    } catch (error) {
      console.error('Error al procesar CSV:', error)
      showNotification(
        `Error al procesar el archivo CSV: ${error.message}`,
        'error'
      )
    }
  }, [createBatchUsers, refetch, showNotification])

  const handleUpdateUser = useCallback(async (id, userData) => {
    console.log('handleUpdateUser called with:', { id, userData })
    try {
      await updateUser(id, userData)
      console.log('User update successful')
      await refetch()
      showNotification('Usuario actualizado exitosamente', 'success')
    } catch (error) {
      console.error('Error updating user:', error)
      showNotification('Error al actualizar el usuario', 'error')
    }
  }, [updateUser, refetch, showNotification])

  const handleDeleteUser = useCallback(async (userId) => {
    console.log('handleDeleteUser called with:', userId)
    try {
      await deleteUser(userId)
      console.log('User deletion successful')
      await refetch()
      showNotification('Usuario eliminado exitosamente', 'success')
    } catch (error) {
      console.error('Error deleting user:', error)
      showNotification('Error al eliminar el usuario', 'error')
    }
  }, [deleteUser, refetch, showNotification])

  const handleBatchDeleteUsers = useCallback(async (userIds) => {
    console.log('handleBatchDeleteUsers called with:', userIds)
    try {
      await batchDeleteUsers(userIds)
      console.log('Batch deletion successful')
      await refetch()
      setSelectedUsers([])
      showNotification('Usuarios eliminados exitosamente', 'success')
    } catch (error) {
      console.error('Error in batch deletion:', error)
      showNotification('Error al eliminar los usuarios', 'error')
    }
  }, [batchDeleteUsers, refetch, showNotification])

  const handleSendCourtesies = useCallback(() => {
    setIsSendCourtesiesDialogOpen(true)
  }, [])

  if (loading) return <LoadingSpinner />

  if (error?.message?.includes('not authorized')) {
    return (
      <DashboardLayout>
        <Box py={{ xs: 1, sm: 2, md: 3 }}>
          <DashboardNavbar absolute />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 200px)',
            textAlign: 'center',
            px: 2
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: '#FFFFFF',
              mb: 3,
              fontFamily: 'Plus Jakarta Sans',
              fontWeight: 700,
              fontSize: { xs: '1.5rem', sm: '2rem' }
            }}
          >
            ¡Bienvenido a Mangoticket!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#98A2B3',
              mb: 4,
              maxWidth: '600px',
              fontFamily: 'Inter',
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            Para comenzar a gestionar usuarios, primero necesitas crear un evento.
          </Typography>
          <Button
            variant="contained"
            onClick={() => window.location.href = '/events/new'}
            sx={{
              backgroundColor: '#F79009',
              color: '#FFFFFF',
              borderRadius: '8px',
              padding: '12px 24px',
              '&:hover': {
                backgroundColor: '#E27D08'
              },
              fontFamily: 'Plus Jakarta Sans',
              fontWeight: 700,
              textTransform: 'none',
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            CREAR MI PRIMER EVENTO
          </Button>
        </Box>
      </DashboardLayout>
    )
  }

  if (error) {
    showNotification(error.message, 'error')
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          backgroundColor: 'inherit',
          borderRadius: '10px',
        }}
      >
        <Typography 
          variant="h6" 
          color="error"
          sx={{
            fontFamily: 'Plus Jakarta Sans',
            fontWeight: 700,
            fontSize: { xs: '1rem', sm: '1.125rem' }
          }}
        >
          {error.message}
        </Typography>
        <Typography 
          variant="body1"
          sx={{
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: { xs: '0.875rem', sm: '1rem' }
          }}
        >
          Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
        </Typography>
      </Box>
    )
  }

  return (
    <DashboardLayout>
      <Box py={{ xs: 1, sm: 2, md: 3 }}>
        <DashboardNavbar absolute />
      </Box>
      
      <Box px={{ xs: 2, sm: 3, md: 4 }}>
        <Box 
          mb={{ xs: 3, sm: 4 }}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: { xs: 2, sm: 0 }
          }}
        >
          <Box>
            <Typography 
              variant="h4" 
              sx={{
                fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' },
                mb: { xs: 0.5, sm: 1 },
                color: '#FFFFFF',
                fontWeight: 700,
                fontFamily: 'Plus Jakarta Sans'
              }}
            >
              Gestión de Usuarios
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                color: '#98A2B3',
                fontSize: { xs: '0.813rem', sm: '0.875rem', md: '1rem' },
                fontFamily: 'Inter',
                fontWeight: 400,
                maxWidth: { sm: '600px', md: '800px' }
              }}
            >
              Administra los usuarios del sistema, agrega nuevos usuarios o importa múltiples usuarios mediante un archivo CSV.
            </Typography>
          </Box>
        </Box>

        <Box 
          sx={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            p: { xs: 1.5, sm: 2, md: 3 },
            mb: { xs: 3, sm: 4 }
          }}
        >
          <Typography 
            variant="h6" 
            sx={{ 
              mb: { xs: 1.5, sm: 2 }, 
              color: '#101828',
              fontFamily: 'Plus Jakarta Sans',
              fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' },
              fontWeight: 700
            }}
          >
            Importar Usuarios
          </Typography>
          <CSVUploader 
            handleFileUpload={handleFileUpload} 
            templateLink="/assets/csv/UsersTemplate.csv"
          />
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: { xs: 1, sm: 2 }, 
            mt: { xs: 1.5, sm: 2 },
            justifyContent: 'flex-end',
            flexWrap: 'wrap'
          }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#F79009',
                color: '#FFFFFF',
                borderRadius: '8px',
                padding: '6px 12px',
                height: '32px',
                minWidth: 'auto',
                '&:hover': {
                  backgroundColor: '#E27D08'
                },
                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                lineHeight: 1,
                whiteSpace: 'nowrap',
                fontFamily: 'Inter',
                fontWeight: 400,
                textTransform: 'none'
              }}
              startIcon={<AddIcon sx={{ 
                color: '#FFFFFF', 
                fontSize: { xs: '1.125rem', sm: '1.5rem' }
              }} />}
              onClick={handleOpen}
            >
              <Typography sx={{ 
                color: '#FFFFFF',
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                lineHeight: 1,
                whiteSpace: 'nowrap'
              }}>
                Agregar Usuario
              </Typography>
            </Button>
          </Box>
        </Box>

        <Box 
          sx={{
            backgroundColor: '#fff',
            borderRadius: '10px',
            p: { xs: 1.5, sm: 2, md: 3 },
            overflowX: 'auto'
          }}
        >
          <UserTable
            users={usersData}
            onEditUser={handleEditUser}
            onDeleteUser={handleDeleteUser}
            onBatchDeleteUsers={handleBatchDeleteUsers}
            refetchUsers={refetch}
            isSmallScreen={isSmallScreen}
            selectedUsers={selectedUsers}
            onSelectUser={handleSelectUser}
            onSelectAllUsers={handleSelectAllUsers}
            onSendCourtesies={handleSendCourtesies}
          />
        </Box>
      </Box>

      <AddEditUserDialog 
        open={open} 
        onClose={handleClose} 
        user={editingUser} 
        refetchUsers={refetch}
      />

      <SendCourtesiesDialog
        open={isSendCourtesiesDialogOpen}
        onClose={handleCloseSendCourtesiesDialog}
        selectedUserIds={selectedUsers}
      />
    </DashboardLayout>
  )
}

export default React.memo(UserManagementPage)
