// src/hooks/useUsersData.js

import { gql, useQuery } from '@apollo/client'
import { useEffect, useRef } from 'react'

const LIST_CUSTOMER_USERS = gql`
  query ListCustomerUsers {
    listCustomerUsers {
      id
      external_id
      email
      name
      created_at
      updated_at
    }
  }
`

export const useUsersData = () => {
  const errorLogged = useRef(false)
  const { data, loading, error, refetch } = useQuery(LIST_CUSTOMER_USERS, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      if (!errorLogged.current) {
        console.error('GraphQL Error Details:', {
          message: error.message,
          graphQLErrors: error.graphQLErrors,
          networkError: error.networkError,
          extraInfo: error.extraInfo
        })
        errorLogged.current = true
      }
    }
  })

  useEffect(() => {
    if (error && !errorLogged.current) {
      console.error('useUsersData error state changed:', {
        message: error.message,
        name: error.name,
        stack: error.stack,
        graphQLErrors: error?.graphQLErrors,
        networkError: error?.networkError
      })
      errorLogged.current = true
    }
  }, [error])

  return {
    usersData: data?.listCustomerUsers || [],
    loading,
    error,
    refetch: async () => {
      errorLogged.current = false
      console.log('Manual refetch triggered')
      try {
        const result = await refetch()
        console.log('Refetch completed:', {
          success: !result.error,
          userCount: result.data?.listCustomerUsers?.length
        })
        return result
      } catch (error) {
        console.error('Refetch error:', error)
        throw error
      }
    }
  }
}
