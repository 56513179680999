import React, { useState, useContext } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import { Box, Button, Typography, Link, FormControlLabel, Checkbox, Select, MenuItem, Grid, TextField, IconButton, Dialog, DialogContent } from '@mui/material'
import * as CountryFlags from 'country-flag-icons/react/3x2'
import CloseIcon from '@mui/icons-material/Close'
import MangoLogoWhite from '../../../assets/images/MangoTicket-Color-White.svg'
import AuthService from '../../../services/auth-service'
import { AuthContext } from '../../../context'
import { toast } from 'react-toastify'

import { BasicLayoutLanding } from '../../templates'
import { FormTextField } from '../../atoms'
import { countries, getPhoneRegex, formatPhoneNumber } from '../../../constants/countries'

const errorMessages = {
  REQUIRED_NAME: 'El nombre es requerido',
  MIN_LENGTH_NAME: 'El nombre debe tener al menos 3 caracteres',
  REQUIRED_EMAIL: 'El correo electrónico es requerido',
  INVALID_EMAIL: 'Ingresa un correo electrónico válido',
  REQUIRED_CONFIRM_EMAIL: 'Confirma tu correo electrónico',
  EMAIL_MISMATCH: 'Los correos electrónicos no coinciden',
  REQUIRED_PASSWORD: 'La contraseña es requerida',
  MIN_LENGTH_PASSWORD: 'La contraseña debe tener al menos 8 caracteres',
  REQUIRED_CONFIRM_PASSWORD: 'Confirma tu contraseña',
  PASSWORD_MISMATCH: 'Las contraseñas no coinciden',
  REQUIRED_PHONE: 'El teléfono es requerido',
  INVALID_PHONE: 'Ingresa un número válido',
  REQUIRED_TERMS: 'Debes aceptar los términos y condiciones',
  REQUIRED_PRIVACY: 'Debes aceptar las políticas de privacidad',
}

const PDFModal = ({ open, onClose, url, title }) => {
  const [hasError, setHasError] = useState(false)

  React.useEffect(() => {
    if (open) {
      setHasError(false)
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth
      PaperProps={{
        sx: {
          width: '90vw',
          height: '90vh',
          maxWidth: '90vw',
          maxHeight: '90vh',
          m: 0,
          backgroundColor: '#fff',
          borderRadius: 2,
          position: 'relative',
          overflow: 'hidden',
        }
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          zIndex: 1,
          color: '#f79009',
          '&:hover': {
            backgroundColor: 'rgba(247, 144, 9, 0.1)',
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      
      <DialogContent sx={{ p: 0, height: '100%' }}>
        {hasError ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              p: 3,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6" color="error" gutterBottom>
              No se pudo cargar el documento
            </Typography>
            <Typography color="text.secondary">
              Por favor, intenta nuevamente más tarde o contacta a soporte si el problema persiste.
            </Typography>
          </Box>
        ) : (
          <iframe
            src={`${url}#toolbar=0&navpanes=0&scrollbar=0`}
            title={title}
            width="100%"
            height="100%"
            style={{ 
              border: 'none',
              display: 'block'
            }}
            frameBorder="0"
            onError={() => setHasError(true)}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

function Register() {
  const navigate = useNavigate()
  const { login } = useContext(AuthContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [termsOpen, setTermsOpen] = useState(false)
  const [privacyOpen, setPrivacyOpen] = useState(false)
  const [touched, setTouched] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    confirmEmail: '',
    phone: '',
    countryCode: 'CL',
    password: '',
    confirmPassword: '',
    termsAccepted: false,
    privacyAccepted: false,
  })

  const [errors, setErrors] = useState({})

  const getCountryInitialNumber = (countryCode) => {
    const country = countries.find(c => c.code === countryCode)
    if (!country || !country.formats[0]) return ''
    
    // Buscar el número entre paréntesis en el formato
    const match = country.formats[0].format.match(/\((\d+)\)/)
    return match ? match[1] : ''
  }

  const getCountryFormat = (countryCode) => {
    const country = countries.find(c => c.code === countryCode)
    if (!country || !country.formats[0]) return ''
    // Obtener solo la parte del formato después del número inicial (si existe)
    const formatParts = country.formats[0].format.split(')')
    return formatParts[formatParts.length - 1].trim()
  }

  const formatPhoneInput = (value, countryCode) => {
    console.log('formatPhoneInput - input:', { value, countryCode })
    // Eliminar todo lo que no sea número
    const numbers = value.replace(/\D/g, '')
    console.log('formatPhoneInput - cleaned numbers:', numbers)
    if (!numbers) return ''

    const country = countries.find(c => c.code === countryCode)
    if (!country || !country.formats[0]) return ''

    // Aplicar el formato del país
    const format = country.formats[0].format
    const formatParts = format.split(' ')
    let result = ''
    let numberIndex = 0

    for (let i = 0; i < formatParts.length && numberIndex < numbers.length; i++) {
      if (i > 0) result += ' '
      const part = formatParts[i]
      const partLength = part.replace(/[^X]/g, '').length
      const digits = numbers.slice(numberIndex, numberIndex + partLength)
      if (!digits.length) break
      result += digits
      numberIndex += partLength
    }

    console.log('formatPhoneInput - formatted output:', result)
    return result
  }

  const getPhonePrefix = (countryCode) => {
    const country = countries.find(c => c.code === countryCode)
    if (!country || !country.formats[0]) return ''
    return `${country.prefix} ${country.mobilePrefix}`
  }

  const handleChange = (e) => {
    const { name, value, checked } = e.target
    let newValue = name === 'termsAccepted' || name === 'privacyAccepted' ? checked : value

    // Formatear el teléfono si es el campo de teléfono
    if (name === 'phone') {
      const prefix = getPhonePrefix(inputs.countryCode)
      const numbers = value.replace(/\D/g, '')
      
      // Si el usuario está intentando borrar el prefijo, no permitirlo
      if (numbers.length < prefix.replace(/\D/g, '').length) {
        newValue = prefix
      } else {
        // Obtener solo la parte del número después del prefijo
        const phoneNumber = numbers.slice(prefix.replace(/\D/g, '').length)
        // Formatear el número
        const formattedNumber = formatPhoneInput(phoneNumber, inputs.countryCode)
        // Combinar el prefijo con el número formateado
        newValue = `${prefix} ${formattedNumber}`
      }
    }

    setInputs(prev => {
      const newInputs = {
        ...prev,
        [name]: newValue,
      }

      // Para email y confirmEmail, esperar un momento antes de validar
      if ((name === 'email' || name === 'confirmEmail') && touched[name]) {
        setTimeout(() => {
          if (name === 'email' && newInputs.confirmEmail && touched.confirmEmail) {
            validateField('confirmEmail', newInputs.confirmEmail, newValue)
          }
          if (name === 'confirmEmail' && newInputs.email && touched.email) {
            validateField('email', newInputs.email)
          }
          validateField(name, newValue)
        }, 300) // Pequeño delay para permitir que el paste se complete
      } else if (touched[name]) {
        // Para otros campos, validar inmediatamente
        validateField(name, newValue)
      }

      return newInputs
    })
  }

  const handleBlur = (e) => {
    const { name } = e.target
    setTouched(prev => ({ ...prev, [name]: true }))
    validateField(name, inputs[name])
  }

  const handlePaste = (e) => {
    const { name } = e.target
    // Limpiar errores al pegar
    setErrors(prev => {
      const { [name]: removedError, ...rest } = prev
      return rest
    })
  }

  const validateField = (name, value, emailValue = inputs.email, passwordValue = inputs.password) => {
    switch (name) {
      case 'name':
        if (!value.trim()) {
          setErrors(prev => ({ ...prev, name: errorMessages.REQUIRED_NAME }))
          return false
        }
        if (value.length < 3) {
          setErrors(prev => ({ ...prev, name: errorMessages.MIN_LENGTH_NAME }))
          return false
        }
        break

      case 'email':
        // Trim y limpieza básica del email
        const cleanEmail = value.trim()
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        if (!cleanEmail) {
          setErrors(prev => ({ ...prev, email: errorMessages.REQUIRED_EMAIL }))
          return false
        }

        // Solo validar el formato si el usuario ha terminado de escribir/pegar
        if (cleanEmail && !emailRegex.test(cleanEmail)) {
          setErrors(prev => ({ ...prev, email: errorMessages.INVALID_EMAIL }))
          return false
        }
        break

      case 'confirmEmail':
        const cleanConfirmEmail = value.trim()
        
        if (!cleanConfirmEmail) {
          setErrors(prev => ({ ...prev, confirmEmail: errorMessages.REQUIRED_CONFIRM_EMAIL }))
          return false
        }

        // Solo comparar si ambos campos tienen valor
        if (cleanConfirmEmail && emailValue && cleanConfirmEmail !== emailValue.trim()) {
          setErrors(prev => ({ ...prev, confirmEmail: errorMessages.EMAIL_MISMATCH }))
          return false
        }
        break

      case 'password':
        if (!value.trim()) {
          setErrors(prev => ({ ...prev, password: errorMessages.REQUIRED_PASSWORD }))
          return false
        }
        if (value.length < 8) {
          setErrors(prev => ({ ...prev, password: errorMessages.MIN_LENGTH_PASSWORD }))
          return false
        }
        break

      case 'confirmPassword':
        if (!value.trim()) {
          setErrors(prev => ({ ...prev, confirmPassword: errorMessages.REQUIRED_CONFIRM_PASSWORD }))
          return false
        }
        if (value !== passwordValue) {
          setErrors(prev => ({ ...prev, confirmPassword: errorMessages.PASSWORD_MISMATCH }))
          return false
        }
        break

      case 'phone':
        console.log('validateField phone - input:', value)
        const numbers = value.replace(/\D/g, '')
        console.log('validateField phone - cleaned numbers:', numbers)
        
        if (!numbers) {
          setErrors(prev => ({ ...prev, phone: errorMessages.REQUIRED_PHONE }))
          return false
        }

        const country = countries.find(c => c.code === inputs.countryCode)
        if (!country || !country.formats[0]) return false

        const prefixDigits = (country.prefix + country.mobilePrefix).replace(/\D/g, '')
        const phoneNumber = numbers.slice(prefixDigits.length)
        console.log('validateField phone - prefix digits:', prefixDigits)
        console.log('validateField phone - number without prefix:', phoneNumber)
        
        // Verificar si el número coincide con alguno de los formatos disponibles
        let isValidFormat = false
        let validationMessage = ''

        for (const formatObj of country.formats) {
          const format = formatObj.format
          const requiredLength = format.replace(/[^X]/g, '').length
          console.log('validateField phone - checking format:', {
            format,
            requiredLength,
            currentLength: phoneNumber.length,
            phoneNumber
          })
          
          if (phoneNumber.length === requiredLength) {
            // Validar el formato específico
            const formatParts = format.split(' ')
            let isValid = true
            let tempNumber = phoneNumber
            
            for (const part of formatParts) {
              const partLength = part.replace(/[^X]/g, '').length
              if (tempNumber.length < partLength) {
                isValid = false
                break
              }
              tempNumber = tempNumber.slice(partLength)
            }

            if (isValid) {
              isValidFormat = true
              console.log('validateField phone - valid format found')
              break
            }
          }

          // Guardar el mensaje de validación del primer formato como fallback
          if (!validationMessage) {
            validationMessage = `Ingresa un número válido: ${formatObj.example}`
          }
        }

        if (!isValidFormat) {
          console.log('validateField phone - invalid format:', validationMessage)
          setErrors(prev => ({ ...prev, phone: validationMessage }))
          return false
        }
        console.log('validateField phone - validation passed')
        break

      default:
        break
    }

    // Limpiar error si la validación pasa
    const { [name]: removedError, ...rest } = errors
    setErrors(rest)
    return true
  }

  const handleTermsOpen = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setTermsOpen(true)
  }

  const handleTermsClose = () => {
    setTermsOpen(false)
  }

  const handlePrivacyOpen = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setPrivacyOpen(true)
  }

  const handlePrivacyClose = () => {
    setPrivacyOpen(false)
  }

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target
    setInputs(prev => ({
      ...prev,
      [name]: checked
    }))

    // Solo validar si ya se han mostrado errores
    if (showErrors) {
      if (checked) {
        setErrors(prev => {
          const { [name === 'termsAccepted' ? 'terms' : 'privacy']: _, ...rest } = prev
          return rest
        })
      } else {
        setErrors(prev => ({
          ...prev,
          [name === 'termsAccepted' ? 'terms' : 'privacy']: 
            name === 'termsAccepted' ? errorMessages.REQUIRED_TERMS : errorMessages.REQUIRED_PRIVACY
        }))
      }
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    
    if (isSubmitting) return

    setShowErrors(true)
    
    // Validar todos los campos
    const isNameValid = validateField('name', inputs.name)
    const isEmailValid = validateField('email', inputs.email)
    const isConfirmEmailValid = validateField('confirmEmail', inputs.confirmEmail)
    const isPhoneValid = validateField('phone', inputs.phone)
    const isPasswordValid = validateField('password', inputs.password)
    const isConfirmPasswordValid = validateField('confirmPassword', inputs.confirmPassword)

    // Validar términos y condiciones
    if (!inputs.termsAccepted) {
      setErrors(prev => ({ ...prev, terms: errorMessages.REQUIRED_TERMS }))
    }

    if (!inputs.privacyAccepted) {
      setErrors(prev => ({ ...prev, privacy: errorMessages.REQUIRED_PRIVACY }))
    }

    if (!isNameValid || !isEmailValid || !isConfirmEmailValid || !isPhoneValid || 
        !isPasswordValid || !isConfirmPasswordValid ||
        !inputs.termsAccepted || !inputs.privacyAccepted) {
      return
    }

    try {
      setIsSubmitting(true)
      const [firstName, ...lastNameParts] = inputs.name.trim().split(' ')
      const lastName = lastNameParts.join(' ')

      const credentials = {
        firstname: firstName,
        lastname: lastName || '',
        email: inputs.email,
        password: inputs.password,
        source: 'mangoticket',
        picture: '',
        reference_id: '',
      }

      const response = await AuthService.register(credentials)
      
      if (response.register?.user) {
        login()
      }
    } catch (error) {
      if (error.response?.errors?.[0]?.message) {
        const errorMessage = error.response.errors[0].message
        if (errorMessage.includes('email')) {
          setErrors(prev => ({ ...prev, email: errorMessage }))
          toast.error('Error con el correo electrónico')
        } else if (errorMessage.includes('password')) {
          setErrors(prev => ({ ...prev, password: errorMessage }))
          toast.error('Error con la contraseña')
        } else {
          setErrors(prev => ({ ...prev, email: 'Error al registrar usuario. Por favor intenta nuevamente.' }))
          toast.error('Error al registrar usuario')
        }
      } else {
        toast.error('Error al registrar usuario')
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <BasicLayoutLanding>
      <Box 
        sx={{ 
          position: 'absolute',
          top: { xs: '40px', sm: '40px', md: '80px' },
          left: '50%',
          transform: 'translateX(-50%)',
          width: { xs: '200px', sm: '320px', md: '360px' },
          mb: { xs: 3, sm: 4 },
        }}
      >
        <Box 
          component="img"
          src={MangoLogoWhite}
          alt="Mango Ticket"
          sx={{ 
            width: '100%',
            height: 'auto',
          }}
        />
      </Box>

      <Card sx={{ 
        borderRadius: { xs: '24px', sm: '32px' },
        width: '100%',
        maxWidth: { xs: '360px', sm: '720px' },
        backgroundColor: '#fff',
        boxShadow: 'none',
        mt: { xs: '120px', sm: '60px', md: '100px' },
      }}>
        <Box sx={{ 
          p: { xs: 2, sm: 4 },
          pt: { xs: 3, sm: 5 },
        }}>
          <Box component="form" onSubmit={submitHandler}>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2 }}>
              <Grid item xs={1} sm={1}>
                <Box>
                  <Typography
                    component="label"
                    sx={{
                      display: 'block',
                      mb: 1,
                      fontSize: { xs: '14px', sm: '16px' },
                      fontWeight: 700,
                      color: '#000',
                      fontFamily: 'Plus Jakarta Sans',
                    }}
                  >
                    Nombre completo
                  </Typography>
                  <FormTextField
                    name="name"
                    value={inputs.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.name)}
                    helperText={null}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      sx: { display: 'none' }
                    }}
                    inputProps={{
                      sx: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }
                    }}
                    sx={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: { xs: '12px', sm: '16px' },
                      '& .MuiOutlinedInput-root': {
                        height: { xs: '48px', sm: '56px' },
                        '& input': {
                          padding: { xs: '12px 16px', sm: '16px 20px' },
                          fontSize: { xs: '14px', sm: '16px' },
                        },
                        '& fieldset': { border: 'none' },
                        '&:hover fieldset': { border: 'none' },
                        '&.Mui-focused fieldset': { border: 'none' },
                      },
                    }}
                  />
                  {errors.name && (
                    <Typography 
                      variant="caption" 
                      color="error"
                      sx={{ 
                        display: 'block',
                        mt: 0.5,
                        ml: 0.5,
                        fontSize: { xs: '11px', sm: '12px' },
                        lineHeight: 1.2,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }}
                    >
                      {errors.name}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={1} sm={1}>
                <Box>
                  <Typography
                    component="label"
                    sx={{
                      display: 'block',
                      mb: 1,
                      fontSize: { xs: '14px', sm: '16px' },
                      fontWeight: 700,
                      color: '#000',
                      fontFamily: 'Plus Jakarta Sans',
                    }}
                  >
                    Correo electrónico
                  </Typography>
                  <FormTextField
                    name="email"
                    type="email"
                    value={inputs.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onPaste={handlePaste}
                    error={Boolean(errors.email)}
                    helperText={null}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      sx: { display: 'none' }
                    }}
                    inputProps={{
                      sx: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }
                    }}
                    sx={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: { xs: '12px', sm: '16px' },
                      '& .MuiOutlinedInput-root': {
                        height: { xs: '48px', sm: '56px' },
                        '& input': {
                          padding: { xs: '12px 16px', sm: '16px 20px' },
                          fontSize: { xs: '14px', sm: '16px' },
                        },
                        '& fieldset': { border: 'none' },
                        '&:hover fieldset': { border: 'none' },
                        '&.Mui-focused fieldset': { border: 'none' },
                      },
                    }}
                  />
                  {errors.email && (
                    <Typography 
                      variant="caption" 
                      color="error"
                      sx={{ 
                        display: 'block',
                        mt: 0.5,
                        ml: 0.5,
                        fontSize: { xs: '11px', sm: '12px' },
                        lineHeight: 1.2,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }}
                    >
                      {errors.email}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={1} sm={1}>
                <Box>
                  <Typography
                    component="label"
                    sx={{
                      display: 'block',
                      mb: 1,
                      fontSize: { xs: '14px', sm: '16px' },
                      fontWeight: 700,
                      color: '#000',
                      fontFamily: 'Plus Jakarta Sans',
                    }}
                  >
                    Confirmar correo electrónico
                  </Typography>
                  <FormTextField
                    name="confirmEmail"
                    type="email"
                    value={inputs.confirmEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onPaste={handlePaste}
                    error={Boolean(errors.confirmEmail)}
                    helperText={null}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      sx: { display: 'none' }
                    }}
                    inputProps={{
                      sx: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }
                    }}
                    sx={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: { xs: '12px', sm: '16px' },
                      '& .MuiOutlinedInput-root': {
                        height: { xs: '48px', sm: '56px' },
                        '& input': {
                          padding: { xs: '12px 16px', sm: '16px 20px' },
                          fontSize: { xs: '14px', sm: '16px' },
                        },
                        '& fieldset': { border: 'none' },
                        '&:hover fieldset': { border: 'none' },
                        '&.Mui-focused fieldset': { border: 'none' },
                      },
                    }}
                  />
                  {errors.confirmEmail && (
                    <Typography 
                      variant="caption" 
                      color="error"
                      sx={{ 
                        display: 'block',
                        mt: 0.5,
                        ml: 0.5,
                        fontSize: { xs: '11px', sm: '12px' },
                        lineHeight: 1.2,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }}
                    >
                      {errors.confirmEmail}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={1} sm={1}>
                <Box>
                  <Typography
                    component="label"
                    sx={{
                      display: 'block',
                      mb: 1,
                      fontSize: { xs: '14px', sm: '16px' },
                      fontWeight: 700,
                      color: '#000',
                      fontFamily: 'Plus Jakarta Sans',
                    }}
                  >
                    Teléfono celular
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Select
                      value={inputs.countryCode}
                      onChange={(e) => {
                        const newCountryCode = e.target.value
                        setInputs(prev => ({
                          ...prev,
                          countryCode: newCountryCode,
                          phone: '',
                        }))
                      }}
                      sx={{
                        width: '100px',
                        height: '56px',
                        backgroundColor: '#f5f5f5',
                        borderRadius: { xs: '12px', sm: '16px' },
                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        '& .MuiSelect-select': {
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          padding: '8px 14px',
                          fontFamily: 'Inter',
                          fontWeight: 400,
                        },
                      }}
                      renderValue={(selected) => {
                        const country = countries.find(c => c.code === selected)
                        const Flag = CountryFlags[country.code]
                        return (
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1,
                          }}>
                            <Box sx={{ 
                              width: 24, 
                              height: 16, 
                              display: 'flex',
                              alignItems: 'center',
                              '& svg': {
                                width: '100%',
                                height: 'auto',
                              }
                            }}>
                              <Flag />
                            </Box>
                            <Typography sx={{ 
                              fontSize: '14px',
                              color: 'rgba(0, 0, 0, 0.6)',
                              fontFamily: 'Inter',
                              fontWeight: 400,
                            }}>
                              {country.code}
                            </Typography>
                          </Box>
                        )
                      }}
                    >
                      {countries.map((country) => {
                        const Flag = CountryFlags[country.code]
                        return (
                          <MenuItem key={country.code} value={country.code}>
                            <Box sx={{ 
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}>
                              <Box sx={{ 
                                width: 24, 
                                height: 16, 
                                display: 'flex',
                                alignItems: 'center',
                                '& svg': {
                                  width: '100%',
                                  height: 'auto',
                                }
                              }}>
                                <Flag />
                              </Box>
                              <Typography sx={{ 
                                fontSize: '14px',
                                color: 'rgba(0, 0, 0, 0.6)',
                                fontFamily: 'Inter',
                                fontWeight: 400,
                              }}>
                                {country.code}
                              </Typography>
                            </Box>
                          </MenuItem>
                        )
                      })}
                    </Select>

                    <Box sx={{ position: 'relative', flexGrow: 1 }}>
                      <TextField
                        name="phone"
                        value={inputs.phone || getPhonePrefix(inputs.countryCode)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.phone)}
                        helperText={null}
                        placeholder={getPhonePrefix(inputs.countryCode) + ' ' + getCountryFormat(inputs.countryCode)}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                          sx: { display: 'none' }
                        }}
                        inputProps={{
                          inputMode: 'numeric',
                          sx: {
                            fontFamily: 'Inter',
                            fontWeight: 400,
                          }
                        }}
                        sx={{
                          backgroundColor: '#f5f5f5',
                          borderRadius: { xs: '12px', sm: '16px' },
                          '& .MuiOutlinedInput-root': {
                            height: { xs: '48px', sm: '56px' },
                            '& input': {
                              padding: { xs: '12px 16px', sm: '16px 20px' },
                              fontSize: { xs: '14px', sm: '16px' },
                            },
                            '& fieldset': { border: 'none' },
                            '&:hover fieldset': { border: 'none' },
                            '&.Mui-focused fieldset': { border: 'none' },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  {errors.phone && (
                    <Typography 
                      variant="caption" 
                      color="error"
                      sx={{ 
                        display: 'block',
                        mt: 0.5,
                        ml: 0.5,
                        fontSize: { xs: '11px', sm: '12px' },
                        lineHeight: 1.2,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }}
                    >
                      {errors.phone}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={1} sm={1}>
                <Box>
                  <Typography
                    component="label"
                    sx={{
                      display: 'block',
                      mb: 1,
                      fontSize: { xs: '14px', sm: '16px' },
                      fontWeight: 700,
                      color: '#000',
                      fontFamily: 'Plus Jakarta Sans',
                    }}
                  >
                    Contraseña
                  </Typography>
                  <FormTextField
                    name="password"
                    type="password"
                    value={inputs.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.password)}
                    helperText={null}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      sx: { display: 'none' }
                    }}
                    inputProps={{
                      sx: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }
                    }}
                    sx={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: { xs: '12px', sm: '16px' },
                      '& .MuiOutlinedInput-root': {
                        height: { xs: '48px', sm: '56px' },
                        '& input': {
                          padding: { xs: '12px 16px', sm: '16px 20px' },
                          fontSize: { xs: '14px', sm: '16px' },
                        },
                        '& fieldset': { border: 'none' },
                        '&:hover fieldset': { border: 'none' },
                        '&.Mui-focused fieldset': { border: 'none' },
                      },
                    }}
                  />
                  {errors.password && (
                    <Typography 
                      variant="caption" 
                      color="error"
                      sx={{ 
                        display: 'block',
                        mt: 0.5,
                        ml: 0.5,
                        fontSize: { xs: '11px', sm: '12px' },
                        lineHeight: 1.2,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }}
                    >
                      {errors.password}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={1} sm={1}>
                <Box>
                  <Typography
                    component="label"
                    sx={{
                      display: 'block',
                      mb: 1,
                      fontSize: { xs: '14px', sm: '16px' },
                      fontWeight: 700,
                      color: '#000',
                      fontFamily: 'Plus Jakarta Sans',
                    }}
                  >
                    Confirmar contraseña
                  </Typography>
                  <FormTextField
                    name="confirmPassword"
                    type="password"
                    value={inputs.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.confirmPassword)}
                    helperText={null}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                      sx: { display: 'none' }
                    }}
                    inputProps={{
                      sx: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }
                    }}
                    sx={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: { xs: '12px', sm: '16px' },
                      '& .MuiOutlinedInput-root': {
                        height: { xs: '48px', sm: '56px' },
                        '& input': {
                          padding: { xs: '12px 16px', sm: '16px 20px' },
                          fontSize: { xs: '14px', sm: '16px' },
                        },
                        '& fieldset': { border: 'none' },
                        '&:hover fieldset': { border: 'none' },
                        '&.Mui-focused fieldset': { border: 'none' },
                      },
                    }}
                  />
                  {errors.confirmPassword && (
                    <Typography 
                      variant="caption" 
                      color="error"
                      sx={{ 
                        display: 'block',
                        mt: 0.5,
                        ml: 0.5,
                        fontSize: { xs: '11px', sm: '12px' },
                        lineHeight: 1.2,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                      }}
                    >
                      {errors.confirmPassword}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={1} sm={2}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: { xs: 1, sm: 3 },
                  justifyContent: 'center',
                  alignItems: { sm: 'flex-start' }
                }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="termsAccepted"
                        checked={inputs.termsAccepted}
                        onChange={handleCheckboxChange}
                        onBlur={handleBlur}
                        sx={{
                          color: errors.terms && showErrors ? 'error.main' : 'primary.main',
                          '&.Mui-checked': {
                            color: errors.terms && showErrors ? 'error.main' : 'primary.main',
                          },
                        }}
                      />
                    }
                    label={
                      <Box>
                        <Typography variant="body2">
                          Acepto los{' '}
                          <Link
                            component="button"
                            onClick={handleTermsOpen}
                            type="button"
                            sx={{
                              color: '#f79009',
                              textDecoration: 'none',
                              background: 'none',
                              border: 'none',
                              cursor: 'pointer',
                              fontSize: 'inherit',
                              fontWeight: 600,
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                              '&:focus': {
                                outline: 'none',
                                color: '#f79009',
                              },
                            }}
                          >
                            términos y condiciones
                          </Link>
                        </Typography>
                        {errors.terms && showErrors && (
                          <Typography color="error" variant="caption" display="block">
                            {errors.terms}
                          </Typography>
                        )}
                      </Box>
                    }
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        name="privacyAccepted"
                        checked={inputs.privacyAccepted}
                        onChange={handleCheckboxChange}
                        onBlur={handleBlur}
                        sx={{
                          color: errors.privacy && showErrors ? 'error.main' : 'primary.main',
                          '&.Mui-checked': {
                            color: errors.privacy && showErrors ? 'error.main' : 'primary.main',
                          },
                        }}
                      />
                    }
                    label={
                      <Box>
                        <Typography variant="body2">
                          Acepto las{' '}
                          <Link
                            component="button"
                            onClick={handlePrivacyOpen}
                            type="button"
                            sx={{
                              color: '#f79009',
                              textDecoration: 'none',
                              background: 'none',
                              border: 'none',
                              cursor: 'pointer',
                              fontSize: 'inherit',
                              fontWeight: 600,
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                              '&:focus': {
                                outline: 'none',
                                color: '#f79009',
                              },
                            }}
                          >
                            políticas de privacidad
                          </Link>
                        </Typography>
                        {errors.privacy && showErrors && (
                          <Typography color="error" variant="caption" display="block">
                            {errors.privacy}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={1} sm={2}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                  sx={{
                    backgroundColor: '#f79009',
                    color: '#fff',
                    textTransform: 'none',
                    fontSize: { xs: '14px', sm: '16px' },
                    fontWeight: 600,
                    py: { xs: 1.5, sm: 1.75 },
                    borderRadius: { xs: '12px', sm: '16px' },
                    mb: { xs: 2, sm: 2.5 },
                    '&:hover': {
                      backgroundColor: '#e67e00',
                    },
                    '&:disabled': {
                      backgroundColor: '#f79009',
                      opacity: 0.7,
                    },
                  }}
                >
                  {isSubmitting ? 'Registrando...' : 'Continuar'}
                </Button>
              </Grid>

              {/* <Grid item xs={1} sm={2}>
                <Typography sx={{ 
                  textAlign: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: { xs: '12px', sm: '14px' },
                  fontFamily: 'Inter, sans-serif',
                  mb: { xs: 2, sm: 2.5 },
                }}>
                  o regístrate con:
                </Typography>
              </Grid>

              <Grid item xs={1} sm={2} sx={{ display: 'flex', justifyContent: 'center', gap: { xs: 1.5, sm: 2 } }}>
                <Button
                  sx={{
                    minWidth: 'auto',
                    width: { xs: '40px', sm: '48px' },
                    height: { xs: '40px', sm: '48px' },
                    p: 0,
                    borderRadius: '50%',
                    border: '1px solid #eee',
                    backgroundColor: '#fff',
                    color: '#1877F2',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      border: '1px solid #ddd',
                    }
                  }}
                >
                  <img 
                    src="/icons/facebook.svg" 
                    alt="Facebook" 
                    width={16}
                    height={16}
                    style={{ opacity: 0.6 }}
                  />
                </Button>
                <Button
                  sx={{
                    minWidth: 'auto',
                    width: { xs: '40px', sm: '48px' },
                    height: { xs: '40px', sm: '48px' },
                    p: 0,
                    borderRadius: '50%',
                    border: '1px solid #eee',
                    backgroundColor: '#fff',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      border: '1px solid #ddd',
                    }
                  }}
                >
                  <img 
                    src="/icons/google.svg" 
                    alt="Google" 
                    width={16}
                    height={16}
                  />
                </Button>
                <Button
                  sx={{
                    minWidth: 'auto',
                    width: { xs: '40px', sm: '48px' },
                    height: { xs: '40px', sm: '48px' },
                    p: 0,
                    borderRadius: '50%',
                    border: '1px solid #eee',
                    backgroundColor: '#fff',
                    color: '#000',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      border: '1px solid #ddd',
                    }
                  }}
                >
                  <img 
                    src="/icons/apple.svg" 
                    alt="Apple" 
                    width={16}
                    height={16}
                    style={{ opacity: 0.6 }}
                  />
                </Button>
              </Grid> */}

              <Grid item xs={1} sm={2}>
                <Typography sx={{ 
                  textAlign: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: { xs: '12px', sm: '14px' },
                  fontFamily: 'Inter, sans-serif',
                }}>
                  ¿Ya tienes una cuenta?{' '}
                  <Link
                    component={RouterLink}
                    to="/auth/login"
                    sx={{
                      color: '#f79009',
                      textDecoration: 'none',
                      fontWeight: 600,
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Iniciar sesión
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>

      <PDFModal
        open={termsOpen}
        onClose={handleTermsClose}
        url="https://mangoticket-legal.nyc3.cdn.digitaloceanspaces.com/TERMINOS%20Y%20CONDICIONES%20MT.pdf"
        title="Términos y Condiciones"
      />

      <PDFModal
        open={privacyOpen}
        onClose={handlePrivacyClose}
        url="https://mangoticket-legal.nyc3.cdn.digitaloceanspaces.com/Poli%CC%81tica%20de%20Privacidad%20MT.pdf"
        title="Política de Privacidad"
      />
    </BasicLayoutLanding>
  )
}

export default Register 