import Icon from '@mui/material/Icon'

const routes = [
  {
    type: 'public',
    name: 'Mango Ticket',
    key: 'landing',
    icon: <Icon fontSize="small">event</Icon>,
    route: '/',
  },
  {
    type: 'public',
    name: 'Privacy Policy',
    key: 'privacy-policy',
    icon: <Icon fontSize="small">security</Icon>,
    route: '/privacy-policy',
  },
  {
    type: 'auth',
    name: 'Login',
    key: 'login',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/auth/login',
  },
  {
    type: 'auth',
    name: 'Register',
    key: 'register',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/auth/register',
  },
  {
    type: 'protected',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">home</Icon>,
    route: '/dashboard',
  },
  {
    type: 'protected',
    name: 'Eventos',
    key: 'events',
    icon: <Icon fontSize="small">event</Icon>,
    route: '/events',
  },
  {
    type: 'protected',
    name: 'Detalles',
    key: 'event-detail',
    icon: <Icon fontSize="small">description</Icon>,
    route: '/events/:eventId/details',
  },
  {
    type: 'protected',
    name: 'Metricas',
    key: 'event-metrics',
    icon: <Icon fontSize="small">assessment</Icon>,
    route: '/events/:eventId/metrics',
  },
  {
    type: 'protected',
    name: 'Crea tu evento',
    key: 'new-event',
    icon: <Icon fontSize="small">event</Icon>,
    route: '/events/new',
  },
  {
    type: 'protected',
    name: 'Usuarios',
    key: 'user-management',
    icon: <Icon fontSize="small">people</Icon>,
    route: '/users',
  },
]

export default routes
