import React, { useRef, useState } from 'react'

import { Box, Typography } from '@mui/material'

import { LandingFooter, LandingHeader } from '../../organisms'
import ContactModal from 'components/molecules/ContactModal/ContactModal'
import theme from './theme'
import { ThemeProvider } from '@mui/material/styles'

function PrivacyPolicy() {
  const faqRef = useRef(null)
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)

  const handleOpenContactModal = () => {
    setIsContactModalOpen(true)
  }

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false)
  }

  const scrollToFAQ = () => {
    faqRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: 'inherit',
          color: '#fff',
          fontFamily: 'Inter, sans-serif',
          width: '100%',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          paddingTop: { xs: '64px', md: '80px' },
        }}
      >
        <LandingHeader scrollToFAQ={scrollToFAQ} onContact={handleOpenContactModal} />
        <main style={{ padding: '2rem', maxWidth: '1100px', margin: '0 auto' }}>
          <Typography variant="h1" sx={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '1rem' }}>
            POLÍTICA DE PRIVACIDAD
          </Typography>
          <Typography variant="h3" sx={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '1rem' }}>
            ¡Bienvenidos a Mango Ticket!
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
            Nos complace darte la más cordial bienvenida a nuestra plataforma de venta en línea. Le pedimos que, por
            favor, lea atentamente la siguiente Política de Privacidad que rige para la utilización de la aplicación
            móvil y página web www.mangoticket.com para la compra de entradas y/o tickets, además de productos
            asociados.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
            La aplicación móvil y página web www.mangoticket.com, en adelante e indistintamente, Mango Ticket y/o
            Ticketera, pertenece a la sociedad MANGO TICKET SpA, RUT 76.706.204-4, con domicilio en calle Apoquindo,
            número 6410, oficina 605, comuna de Las Condes, Región Metropolitana, cuyo representante legal es FRANCISCO
            JAVIER PÉREZ RODRÍGUEZ.
          </Typography>
          <Typography variant="h3" sx={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '1rem' }}>
            PRIMERO: Política de privacidad.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
            Mango Ticket se compromete a mantener la confidencialidad y seguridad de los datos personales de los
            Usuarios, adoptando las medidas técnicas, organizativas y legales necesarias para evitar su pérdida,
            alteración, acceso no autorizado o uso indebido. Sin perjuicio de lo anterior, cabe señalar que, hasta la
            fecha no existe un mecanismo que garantice una completa inmunidad a ataques cibernéticos de terceros que
            buscan obtener o interceptar tal información de modo ilícito y sin autorización.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
            La información proporcionada por los Usuarios será utilizada, única y exclusivamente, para:
            <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
              1. El tratamiento de datos;
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
              2. La validación de órdenes de compra;
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
              3. La comercialización de productos y servicios;
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
              4. El proponer mejoras en torno a la experiencia del Usuario;
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
              5. Cumplir fines comerciales entre Mango Ticket y del Organizador del evento.
            </Typography>
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
            La Ticketera solo compartirá los datos personales de los Usuarios con terceros cuando sea necesario para la
            prestación del servicio, el cumplimiento de una obligación legal o el ejercicio de un derecho legítimo. En
            ningún caso, Mango Ticket venderá, cederá o transferirá los datos personales de los Usuarios a terceros sin
            su consentimiento previo.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
            El Usuario podrá revocar la autorización al tratamiento de datos personales pudiendo acceder, rectificar,
            cancelar y oponerse, siempre y todo momento, conforme a los derechos consagrados en la ley 19.628 (ARCO).
            Para realizar la revocación el Usuario deberá contactarse con el Servicio al Cliente -conforme a lo
            dispuesto en la cláusula Décimo Primero - solicitando la cancelación de vigencia de la cuenta. Para ello,
            deberá adjuntar una copia de su cédula de identidad por ambos lados. La cancelación no podrá ser solicitada
            en caso de que hubiere procesos de entrega de tickets o devolución pendientes con el Usuario.
          </Typography>
          <Typography variant="h3" sx={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '1rem' }}>
            SEGUNDO: Uso de cookies y seguridad.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
            Mango Ticket utiliza y almacena cookies propias y/o de terceros para mejorar la experiencia de navegación,
            ofrecer contenidos personalizados, analizar el tráfico y realizar estadísticas. Las cookies son archivos que
            se almacenan en el dispositivo del Usuario y que permiten reconocer sus preferencias, hábitos e intereses.
            El Usuario podrá, en todo momento, negar el uso de cookies, sin embargo, ello podría implicar un peor
            funcionamiento en la optimización de la plataforma respecto del servicio prestado.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
            Mango Ticket no garantiza la privacidad y seguridad de la información y datos proporcionados por el Usuario,
            ni tampoco garantiza que terceros no autorizados tengan acceso o conocimiento del uso, transacciones, datos,
            características, condiciones o circunstancias que el Usuario realice en la plataforma. Lo anterior en virtud
            de que no existen mecanismos que permitan asegurar la inmunidad frente a ataques cibernéticos de terceros
            que buscan obtener o interceptar tal información de modo ilícito y sin autorización.
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'justify' }}>
            Dicho lo anterior, la Ticketera se exime de cualquier responsabilidad por los daños y perjuicios que
            pudieran originarse por el acceso al conocimiento de la información y datos de los Usuarios, por parte de
            terceros no autorizados.
          </Typography>
        </main>

        <ContactModal open={isContactModalOpen} onClose={handleCloseContactModal} />
      </Box>
      <LandingFooter />
    </ThemeProvider>
  )
}

export default PrivacyPolicy
