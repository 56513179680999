import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import { useStatisticsData } from '../../../hooks/dashboard/useStatisticsData'
import { StatisticsCard } from '../../organisms'
import { Box, LoadingSpinner, Typography } from '../../atoms'

function Statistics() {
  const { statisticsData, loading, error } = useStatisticsData()
  const navigate = useNavigate()
  const location = useLocation()
  const [subscriptionKey, setSubscriptionKey] = useState(0)

  useEffect(() => {
    setSubscriptionKey((prevKey) => prevKey + 1)
  }, [location.pathname])

  const handleOnClick = useCallback(
    (url) => {
      navigate(url)
    },
    [navigate]
  )

  if (loading) return <LoadingSpinner />
  if (error) {
    return (
      <Box>
        <Typography variant="h6" color="error">
          {error.message}
        </Typography>
        <Typography variant="body1">
          Por favor, intenta refrescar la página o contacta a soporte si el problema persiste.
        </Typography>
      </Box>
    )
  }

  return (
    <Box key={subscriptionKey}>
      <Box mb={{ xs: 2, sm: 3, md: 4 }}>
        <Typography 
          variant="h4" 
          color="white" 
          fontWeight="bold"
          sx={{
            fontSize: { xs: '1.25rem', sm: '1.5rem', md: '2rem' },
            mb: { xs: 0.5, sm: 1, md: 2 },
            lineHeight: { xs: 1.3, sm: 1.4, md: 1.5 }
          }}
        >
          Analíticas del último mes
        </Typography>
        <Typography 
          variant="body1" 
          color="secondary"
          sx={{
            fontSize: { xs: '0.813rem', sm: '0.875rem', md: '1rem' },
            lineHeight: { xs: 1.4, sm: 1.5, md: 1.75 },
            opacity: 0.8,
            color: "#98A2B3"
          }}
        >
          Visualiza el impacto de tus eventos recientes, con detalles sobre ingresos, tickets vendidos y el éxito de
          cada uno.
        </Typography>
      </Box>
      <Grid 
        container 
        spacing={{ xs: 1.5, sm: 2, md: 2 }} 
        alignItems="stretch"
      >
        {statisticsData.map((stat, index) => (
          <Grid 
            item 
            xs={12} 
            sm={6}
            md={4} 
            lg={4} 
            key={index}
          >
            <Box 
              height="100%" 
              minHeight={{ xs: '100px', sm: '180px', md: '140px' }}
              sx={{
                '& > *': {
                  height: '100%',
                  '& .MuiCard-root': {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }
                }
              }}
            >
              <StatisticsCard
                icon={stat.icon}
                title={stat.name}
                value={stat.value}
                handleOnClick={handleOnClick}
                redirectTo={stat.redirectTo}
                variations={stat.variations ?? []}
                type={stat.type}
                format={stat.format}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default React.memo(Statistics)
