import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import { Features } from '../index'

const BenefitSection = ({ tagline, title, description, imageUrl, altText, isReversed, onContact }) => {
  const titleParts = title.split('.')
  const titleFirstPart = titleParts[0] + '.'
  const titleSecondPart = titleParts[1]

  return (
    <Box
      component="section"
      sx={{
        backgroundColor: '#04121a',
        padding: { xs: '40px 20px', md: '112px 64px' },
        display: 'flex',
        alignItems: 'center',
        gap: { xs: '40px', md: '80px' },
        flexDirection: isReversed ? { xs: 'column', md: 'row-reverse' } : { xs: 'column', md: 'row' },
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: { xs: '40px', md: '80px' },
            flexDirection: { xs: 'column', md: isReversed ? 'row-reverse' : 'row' },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                color: '#f79009',
                fontSize: '16px',
                fontWeight: 600,
                marginBottom: '16px',
              }}
            >
              {tagline}
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '40px', md: '48px' },
                fontFamily: '"Plus Jakarta Sans", sans-serif',
                fontWeight: 700,
                lineHeight: 1.2,
                marginBottom: '24px',
              }}
            >
              {titleFirstPart}{' '}
              <Box component="span" sx={{ color: '#fff' }}>
                {titleSecondPart}
              </Box>
            </Typography>
            <Typography
              sx={{
                color: '#aaa',
                fontSize: '18px',
                fontFamily: '"Inter", sans-serif',
                fontWeight: 400,
                marginBottom: '32px',
              }}
            >
              {description}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              gap: { xs: '8px', md: '24px' },
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'center' 
            }}>
              <Button
                variant="contained"
                href="/events/new"
                sx={{
                  backgroundColor: '#f79009',
                  color: '#fff',
                  borderRadius: '10px',
                  padding: { xs: '12px 16px', md: '12px 24px' },
                  fontSize: { xs: '14px', md: '16px' },
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                  minWidth: 'auto',
                  '&:hover': {
                    backgroundColor: '#e67e00',
                  },
                }}
              >
                Crea tu evento
              </Button>
              <Button
                variant="outlined"
                onClick={onContact}
                sx={{
                  color: '#fff',
                  borderColor: '#f79009',
                  borderRadius: '10px',
                  padding: { xs: '12px 16px', md: '12px 24px' },
                  fontSize: { xs: '14px', md: '16px' },
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                  minWidth: 'auto',
                  '&:hover': {
                    borderColor: '#e67e00',
                    backgroundColor: 'rgba(247, 144, 9, 0.1)',
                  },
                }}
              >
                Contáctanos
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={imageUrl}
              alt={altText}
              loading="lazy"
              sx={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

const Benefits = ({ onContact }) => {
  const benefitsData = [
    {
      tagline: 'Eficiencia',
      title: 'Facilita el acceso. Aumenta tus ventas.',
      description: 'Tus asistentes tendrán sus entradas y tragos en un solo lugar.',
      imageUrl:
        'https://mangoticket.sfo3.cdn.digitaloceanspaces.com/landing/benefits1.png',
      altText: 'Easy access to tickets and drinks',
    },
    {
      tagline: 'Fluidez',
      title: 'Todo en un solo código. Facil y accesible.',
      description:
        'Ofrece a tus asistentes la facilidad de gestionar sus entradas y tragos directamente desde su celular con un solo QR.',
      imageUrl:
        'https://mangoticket.sfo3.cdn.digitaloceanspaces.com/landing/benefits2.png',
      altText: 'Single QR code for tickets and drinks',
    },
  ]

  return (
    <>
      <BenefitSection {...benefitsData[0]} isReversed={false} onContact={onContact} />
      <Features onContact={onContact} />
      <BenefitSection {...benefitsData[1]} isReversed={true} onContact={onContact} />
    </>
  )
}

export default Benefits
