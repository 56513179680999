import { useEffect, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import graphqlService from '../../services/graphql.service'
import { useUser } from './useUser'

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const { user, loading: userLoading, clearUserData } = useUser()

  const checkAuthentication = useCallback(async () => {
    try {
      const response = await graphqlService.verifyAuth()

      if (response.isAuthenticated) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
        clearUserData()
      }
    } catch (error) {
      setIsAuthenticated(false)
      clearUserData()
    } finally {
      setLoading(false)
    }
  }, [clearUserData])

  useEffect(() => {
    checkAuthentication()
  }, [checkAuthentication])

  useEffect(() => {
    const publicRoutes = ['/auth/login', '/auth/register', '/', '/privacy-policy']
    if (!loading && !userLoading) {
      const isPublicRoute = publicRoutes.includes(location.pathname)

      if (isAuthenticated && isPublicRoute && location.pathname !== '/') {
        navigate('/dashboard')
      } else if (!isAuthenticated && !isPublicRoute) {
        const returnUrl = location.pathname + location.search
        navigate(`/auth/login?returnUrl=${encodeURIComponent(returnUrl)}`)
      }
    }
  }, [isAuthenticated, loading, userLoading, navigate, location])

  const logout = useCallback(async () => {
    try {
      await graphqlService.logout()
      setIsAuthenticated(false)
      clearUserData()
      navigate('/auth/login', { replace: true })
    } catch (error) {
      console.error('Logout error:', error)
    }
  }, [navigate, clearUserData])

  return {
    isAuthenticated,
    loading: loading || userLoading,
    setIsAuthenticated,
    user,
    logout,
    checkAuthentication,
  }
}
